<template>
  <div class="guiz-step-item active" data-quiz-question="7" data-selected-input-name="quiz-question-health-feel">
    <div class="" data-selected-input-value="quiz-question-health-feel-good"
         data-selected-input-name="quiz-question-age">
      <div class="" data-selected-input-value="quiz-question-age-18-29">
        <div class="guiz-step-item-inner text-left bg-theme-color-blue">
          <div class="container container-930px wow fadeIn" data-wow-delay="0.2s"
               v-wow="{ 'animation-name': 'fadeIn','animation-duration': '0.2s'}">
            <div class="block-info-card">
              <div class="row flex-row-reverse">
                <div class="col-md-6">
                  <div class="img-wr wow zoomIn" data-wow-delay="0.5s"
                       v-wow="{ 'animation-name': 'zoomIn','animation-duration': '0.5s'}">
                    <img :src="params.image" alt="">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="text-wr wow fadeInLeft" data-wow-delay="0.2s"
                       v-wow="{ 'animation-name': 'fadeInLeft','animation-duration': '0.2s'}">
                    <h4 class="h2" v-if="params.description1" v-html="params.description1"></h4>
                    <h4 class="h2" v-if="params.description2" v-html="params.description2"></h4>
                    <button style="z-index: 99999" @click="submit" :disabled="showLoader" type="button"
                            value="btn-step-next"
                            class="btn-step-next-simple btn-main bg-theme-color-green wow zoomIn"
                            data-wow-delay="0.8s" v-wow="{ 'animation-name': 'zoomIn','animation-duration': '0.8s'}">
                      <div v-if="showLoader" class="spinner-border spinner-border-sm" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                      <span> Continue</span>
                      <i class="fa fa-angle-right"></i>
                    </button>
                    <h5 class="h2 display-flex lottie-parent">
                      <i>
                        <v-lottie-player
                            class="lottie-main"
                            width="100%"
                            height="0%"
                            name="scooterAnim"
                            loop
                            :animationData="require('../../assets/lottie/lf20_nub9b5dv.json')"
                        />
                      </i>
                      <span>Great progress! You’ve already <strong>completed {{ params.progress }}%</strong> of the quiz.</span>
                    </h5>
                    <div class="quiz-progress-bar">
                      <div class="quiz-progress" :style="{ width: progress + '%' }"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>

.lottie-main svg {
  position: absolute;
  height: 500% !important;
  width: 500% !important;
  left: -200%;
  top: -218%;
}

.lottie-main {
  position: relative;
  height: 0 !important;
  width: 100% !important;
  padding: 0;
  padding-bottom: 0px;
  padding-bottom: 100%;
}
</style>

<script>
import VueLottiePlayer from 'vue-lottie-player'

export default {
  components: {
    vLottiePlayer: VueLottiePlayer,
  },
  props: {
    params: Object,
    id: String,
  },
  data () {
    return {
      showLoader: false,
      progress: 0,
      mainTimeOut: null,
      perIntTimeout: null
    }
  },
  methods: {
    submit () {
      clearTimeout(this.mainTimeOut)
      this.showLoader = true
      const input = {
        questionId: this.id,
        answer: this.params.answer
      }
      this.$emit('onSubmit', input)
    },
    updateProgress () {
      this.progress += 6
      if (this.progress > 100) {
        this.progress = 100
        this.submit()
      }
    }
  },
  mounted () {
    this.$root.$on('hide-loader', async () => {
      this.showLoader = false
    })
    const self = this
    window.addEventListener('keyup', function () {
      if (event.keyCode === 13) {
        self.submit()
      }
    })
    this.$root.$emit('header-update', 'dark')
    clearTimeout(this.perIntTimeout)
    this.perIntTimeout = setInterval(() => {
      this.updateProgress()
    }, 1000)
  }
}
</script>
