<template>
  <div class="guiz-step-item active" data-quiz-question="21">
    <div class="guiz-step-item-inner guiz-step-results text-center bg-transparent">
      <h2 class="h1 font-AddingtonCF theme-color-blue wow fadeInUp" data-wow-delay="0.3s" v-wow="{ 'animation-name': 'fadeInUp','animation-duration': '0.3s'}"><span
          class="theme-color-green">Thanks so much</span> for helping us learn more about you and
        your specific health needs.</h2>
      <section class="plan">
        <div class="container">
          <div class="plans-table theme-color-blue bg-white">
            <div class="plans-table-row plans-table-header row wow fadeInUp" data-wow-delay="0.3s" v-wow="{ 'animation-name': 'fadeInUp','animation-duration': '0.3s'}">
              <div class="col-6">
                <h5>Membership price</h5>
              </div>
              <div class="col-3">
                <span class="plan-label bg-white">LifeMD Membership</span>
                <h3><sup>$</sup>15<sub>/month</sub></h3>
              </div>
              <div class="col-3">
                <span class="plan-label bg-theme-color-green-light">Unlimited Membership</span>
                <h3><sup>$</sup>99<sub>/month</sub></h3>
              </div>
            </div>
            <div class="plans-table-row row wow fadeInUp" data-wow-delay="0.3s" v-wow="{ 'animation-name': 'fadeInUp','animation-duration': '0.3s'}">
              <div class="col-6">
                <h5>No insurance required</h5>
              </div>
              <div class="col-3">
                <i class="icon-rounded bg-theme-color-green icon-checked"></i>
              </div>
              <div class="col-3">
                <i class="icon-rounded bg-theme-color-green icon-checked"></i>
              </div>
            </div>
            <div class="plans-table-row row wow fadeInUp" data-wow-delay="0.3s" v-wow="{ 'animation-name': 'fadeInUp','animation-duration': '0.3s'}">
              <div class="col-6">
                <h5>Unlimited no cost appointments <br> <span>($49/appointment)</span></h5>
              </div>
              <div class="col-3">
                <i class="icon-rounded bg-gray icon-checked icon-times"></i>
              </div>
              <div class="col-3">
                <i class="icon-rounded bg-theme-color-green icon-checked"></i>
              </div>
            </div>
            <div class="plans-table-row row wow fadeInUp" data-wow-delay="0.3s" v-wow="{ 'animation-name': 'fadeInUp','animation-duration': '0.3s'}">
              <div class="col-6">
                <h5>Your own dedicated primary care provider*</h5>
              </div>
              <div class="col-3">
                <i class="icon-rounded bg-gray icon-checked icon-times"></i>
              </div>
              <div class="col-3">
                <i class="icon-rounded bg-theme-color-green icon-checked"></i>
              </div>
            </div>
            <div class="plans-table-row row wow fadeInUp" data-wow-delay="0.3s" v-wow="{ 'animation-name': 'fadeInUp','animation-duration': '0.3s'}">
              <div class="col-6">
                <h5>Pesonalized onboarding during first appointment</h5>
              </div>
              <div class="col-3">
                <i class="icon-rounded bg-gray icon-checked icon-times"></i>
              </div>
              <div class="col-3">
                <i class="icon-rounded bg-theme-color-green icon-checked"></i>
              </div>
            </div>
            <div class="plans-table-row row wow fadeInUp" data-wow-delay="0.3s" v-wow="{ 'animation-name': 'fadeInUp','animation-duration': '0.3s'}">
              <div class="col-6">
                <h5>Address more than 1 health issue in an appointment</h5>
              </div>
              <div class="col-3">
                <i class="icon-rounded bg-gray icon-checked icon-times"></i>
              </div>
              <div class="col-3">
                <i class="icon-rounded bg-theme-color-green icon-checked"></i>
              </div>
            </div>
            <div class="plans-table-row row wow fadeInUp" data-wow-delay="0.3s" v-wow="{ 'animation-name': 'fadeInUp','animation-duration': '0.3s'}">
              <div class="col-6">
                <h5>Message with your doctor about more than 1 issue <br> <span>(the 1 appointment issue)</span>
                </h5>
              </div>
              <div class="col-3">
                <i class="icon-rounded bg-gray icon-checked icon-times"></i>
              </div>
              <div class="col-3">
                <i class="icon-rounded bg-theme-color-green icon-checked"></i>
              </div>
            </div>
            <div class="plans-table-row row wow fadeInUp" data-wow-delay="0.3s" v-wow="{ 'animation-name': 'fadeInUp','animation-duration': '0.3s'}">
              <div class="col-6">
                <h5>24/7 messaging with your medical team</h5>
              </div>
              <div class="col-3">
                <i class="icon-rounded bg-theme-color-green icon-checked"></i>
              </div>
              <div class="col-3">
                <i class="icon-rounded bg-theme-color-green icon-checked"></i>
              </div>
            </div>
            <div class="plans-table-row row wow fadeInUp" data-wow-delay="0.3s" v-wow="{ 'animation-name': 'fadeInUp','animation-duration': '0.3s'}">
              <div class="col-6">
                <h5>Prescriptions and refills**</h5>
              </div>
              <div class="col-3">
                <i class="icon-rounded bg-theme-color-green icon-checked"></i>
              </div>
              <div class="col-3">
                <i class="icon-rounded bg-theme-color-green icon-checked"></i>
              </div>
            </div>
            <div class="plans-table-row row wow fadeInUp" data-wow-delay="0.3s" v-wow="{ 'animation-name': 'fadeInUp','animation-duration': '0.3s'}">
              <div class="col-6">
                <h5>Lab orders • Up to 95% OFF!</h5>
              </div>
              <div class="col-3">
                <i class="icon-rounded bg-theme-color-green icon-checked"></i>
              </div>
              <div class="col-3">
                <i class="icon-rounded bg-theme-color-green icon-checked"></i>
              </div>
            </div>
            <div class="plans-table-row row wow fadeInUp" data-wow-delay="0.3s" v-wow="{ 'animation-name': 'fadeInUp','animation-duration': '0.3s'}">
              <div class="col-6">
                <h5>Imaging referrals</h5>
              </div>
              <div class="col-3">
                <i class="icon-rounded bg-gray icon-checked icon-times"></i>
              </div>
              <div class="col-3">
                <i class="icon-rounded bg-theme-color-green icon-checked"></i>
              </div>
            </div>
            <div class="plans-table-row row wow fadeInUp" data-wow-delay="0.3s" v-wow="{ 'animation-name': 'fadeInUp','animation-duration': '0.3s'}">
              <div class="col-6">
                <h5>Specialist referrals</h5>
              </div>
              <div class="col-3">
                <i class="icon-rounded bg-gray icon-checked icon-times"></i>
              </div>
              <div class="col-3">
                <i class="icon-rounded bg-theme-color-green icon-checked"></i>
              </div>
            </div>
            <div class="plans-table-row plans-table-footer row wow fadeInUp" data-wow-delay="0.3s" v-wow="{ 'animation-name': 'fadeInUp','animation-duration': '0.3s'}">
              <div class="col-6 d-none d-md-block">
              </div>
              <div class="col-6 col-md-3">
                <a href="https://care.lifemd.com/signup/p/b1/appointment-details?c=Health+%26+Wellness&promo=4health50" class="btn-main bg-theme-color-blue wow zoomIn"
                   data-wow-delay="0.8s" v-wow="{ 'animation-name': 'zoomIn','animation-duration': '0.8s'}">
                  <span>get A $15/mo Membership</span>
                </a>
              </div>
              <div class="col-6 col-md-3">
                <a href="https://care.lifemd.com/signup/u/b1/appointment-details?c=Health+%26+Wellness&promo=4health50" class="btn-main bg-theme-color-blue wow zoomIn"
                   data-wow-delay="0.8s" v-wow="{ 'animation-name': 'zoomIn','animation-duration': '0.8s'}">
                  <span>get A $99/mo Membership</span>
                </a>
              </div>
            </div>
          </div>
          <div class="text-left">
            <h6 class="color-gray">
              <span>* With the option to choose a new primary care provider at any time</span>
              <br>
              <span>** Outside of controlled substances</span>
            </h6>
          </div>
        </div>
      </section>
      <div class="box-item box-quiz-results text-center bg-transparent">
        <div class="container container-800px wow fadeInUp" data-wow-delay="0.2s" v-wow="{ 'animation-name': 'fadeInUp','animation-duration': '0.2s'}">
          <h2 class="h1 font-AddingtonCF theme-color-blue"><span class="theme-color-green">Thanks so much</span>
            for helping us learn more about you and your specific health needs.</h2>
          <h2 class="h1 font-AddingtonCF theme-color-blue">Based on your answers...</h2>
          <p><strong>We’re ready to give you the personalized support you deserve.</strong></p>
          <p>Health is a lifelong journey. And we’ll be here for you along the way, with quality
            healthcare delivered right to your smartphone or computer. From
            telehealth visits with U.S based,
            board-certified doctors to 24/7
            messaging access with a dedicated
            medical team to prescription
            refills made easy, Life<span class="theme-color-green">MD</span> is
            healthcare built around you, your
            life, and your schedule. <strong>No insurance
              needed!</strong></p>
          <p>Head over to LifeMD.com to learn more or to schedule your first appointment.</p>
          <a href="https://care.lifemd.com/signup/p/b1/appointment-details?c=Health+%26+Wellness&promo=4health50" class="btn-main btn-large bg-theme-color-green wow zoomIn"
             data-wow-delay="0.8s" v-wow="{ 'animation-name': 'zoomIn','animation-duration': '0.8s'}">
            <span>TALK TO A DOCTOR</span>
            <i class="fa fa-angle-right"></i>
          </a>
          <div class="img-wr wow zoomIn" data-wow-delay="0.2s" v-wow="{ 'animation-name': 'zoomIn','animation-duration': '0.2s'}">
            <img src="@/assets/img/doctor-phone.png" alt="">
          </div>
        </div><!-- END of .container -->
      </div>

      <section class="health text-left">
        <div class="center">

          <div class="text-center">
            <h2 class="h1 font-AddingtonCF theme-color-blue">Experience a new kind of
              healthcare.</h2>
            <p><strong>Come discover a healthcare solution built around you and your life.</strong>
            </p>
            <div class="img-wr wow zoomIn" data-wow-delay="0.2s" v-wow="{ 'animation-name': 'zoomIn','animation-duration': '0.2s'}">
              <img src="@/assets/img/No-Insurance-shield.png" alt="">
            </div>
          </div>


          <div class="health-row">
            <a href="https://care.lifemd.com/signup/p/b1/appointment-details?c=Health+%26+Wellness&promo=4health50" class="health-item hoverEffects an an-left an-no">
              <div class="inner">
                <div class="hover">
                  <v-lottie-player
                      name="scooterAnim"
                      loop
                      :animationData="require('../../assets/lottie/lines_u_block_1.json')"/>
                </div>
                <div class="wrap hoverEffects">
                  <div>
                    <h3>Medical Access, <span>24/7</span></h3>
                    <p>Connect day or night with a<br/>
                      dedicated medical team.
                    </p>
                    <div class="link"><span>get started</span></div>
                  </div>
                  <div class="icon">
                    <v-lottie-player
                        name="scooterAnim"
                        loop
                        :animationData="require('../../assets/lottie/lifemd-247access.json')"
                    />
                  </div>
                </div>
              </div>
            </a>
            <a href="https://care.lifemd.com/signup/p/b1/appointment-details?c=Health+%26+Wellness&promo=4health50" class="health-item hoverEffects an an-right an-no">
              <div class="inner">
                <div class="hover">
                  <v-lottie-player
                      name="scooterAnim"
                      loop
                      :animationData="require('../../assets/lottie/lines_u_block_1.json')"/>
                </div>
                <div class="wrap hoverEffects">
                  <div>
                    <h3>Prescriptions Made <span>Simple</span></h3>
                    <p>Get a prescription written and <br> pick it up at your local
                      pharmacy.</p>
                    <div class="link"><span>get started</span></div>
                  </div>
                  <div class="icon">
                    <v-lottie-player
                        name="scooterAnim"
                        loop
                        :animationData="require('../../assets/lottie/No_Internet_Internet_on_the_go-pana_1.json')"
                    />
                  </div>
                </div>
              </div>
            </a>
            <a href="https://care.lifemd.com/signup/p/b1/appointment-details?c=Health+%26+Wellness&promo=4health50" class="health-item hoverEffects an an-left playning">
              <div class="inner">
                <div class="hover">
                  <v-lottie-player
                      name="scooterAnim"
                      loop
                      :animationData="require('../../assets/lottie/lines_u_block_1.json')"/>
                </div>
                <div class="wrap hoverEffects">
                  <div>
                    <h3>Comprehensive <span>Lab Work</span></h3>
                    <p>Affordable labs, whenever you need<br> them, reviewed by your doctor.
                    </p>
                    <div class="link"><span>get started</span></div>
                  </div>
                  <div class="icon">
                    <v-lottie-player
                        name="scooterAnim"
                        loop
                        :animationData="require('../../assets/lottie/lifemd-labtests.json')"
                    />
                  </div>
                </div>
              </div>
            </a>
            <a href="https://care.lifemd.com/signup/p/b1/appointment-details?c=Health+%26+Wellness&promo=4health50" class="health-item hoverEffects an an-right">
              <div class="inner">
                <div class="hover">
                  <v-lottie-player
                      name="scooterAnim"
                      loop
                      :animationData="require('../../assets/lottie/lines_u_block_1.json')"/>
                </div>
                <div class="wrap hoverEffects playning">
                  <div>
                    <h3>Doctors Who <span>Care</span></h3>
                    <p>Our affiliated doctors care<br> about you and your health.</p>
                    <div class="link"><span>get started</span></div>
                  </div>
                  <div class="icon">
                    <v-lottie-player
                        name="scooterAnim"
                        loop
                        :animationData="require('../../assets/lottie/lifemd-doctorsthatcare.json')"
                    />
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </section>

      <div class="box-item box-our-secret text-left bg-gray">
        <div class="container wow fadeInUp" data-wow-delay="0.2s" v-wow="{ 'animation-name': 'fadeInUp','animation-duration': '0.2s'}">
          <div class="row align-items-end">
            <div class="col-md-6">
              <div class="text-wr">
                <h2 class="h1 font-AddingtonCF theme-color-blue">Our secret to great virtual
                  care is great doctors.</h2>
                <ul class="list-styled-circles">
                  <li>Board-certified doctors</li>
                  <li>Service in all 50 states</li>
                  <li>Access to care when you need it</li>
                </ul>
                <a href="https://care.lifemd.com/signup/p/b1/appointment-details?c=Health+%26+Wellness&promo=4health50" class="btn-main bg-theme-color-blue wow zoomIn"
                   data-wow-delay="0.8s" v-wow="{ 'animation-name': 'zoomIn','animation-duration': '0.8s'}">
                  <span>TALK TO A DOCTOR</span>
                  <i class="fa fa-angle-right"></i>
                </a>
                <p><strong>No insurance is needed</strong> to use LifeMD’s services.</p>
              </div>
            </div>
            <div class="col-md-6">
              <div class="image-wr wow fadeInUp" data-wow-delay="0.5s" v-wow="{ 'animation-name': 'fadeInUp','animation-duration': '0.5s'}">
                <svg viewBox="0 0 568 660">
                  <path class="path"
                        d="M563 1C534 8 411.03 56 444 146C464.437 201.788 378 273 337 304C199 408.341 -64.2 556.6 23 651"
                        stroke="#63CBC8" fill="none" stroke-width="2"/>
                </svg>

                <div class="slider-doctors">
                  <VueSlickCarousel v-bind="settings">
                    <div class="slide-item">
                      <img src="@/assets/img/lifestyle_circle_frame_01.png" alt="">
                    </div>
                    <div class="slide-item">
                      <img src="@/assets/img/lifestyle_circle_frame_02.png" alt="">
                    </div>
                    <div class="slide-item">
                      <img src="@/assets/img/lifestyle_circle_frame_03.png" alt="">
                    </div>
                    <div class="slide-item">
                      <img src="@/assets/img/lifestyle_circle_frame_04.png" alt="">
                    </div>
                  </VueSlickCarousel>
                </div>
              </div>
            </div>
          </div>
        </div><!-- END of .container -->
      </div>
      <footer class="footer" style="margin-top: -11px">
        <div class="center">
          <div class="footer-row">
            <div class="footer-item">
              <a href="/" class="footer-logo"></a>
              <div class="social">
                <a href="https://www.instagram.com/lifemd/" target="_blank" class="ic-fb"></a>
                <a href="https://twitter.com/lifemd" target="_blank" class="ic-tw"></a>
              </div>
            </div>
            <div class="footer-item">
              <h3>Resources</h3>
              <ul>
                <li><a href="https://lifemd.com/contact">Contact Us</a></li>
                <li><a href="https://lifemd.com/faq">FAQs</a></li>
                <li><a href="https://support.lifemd.com/">Support</a></li>
              </ul>
            </div>
            <div class="footer-item">
              <h3>Learn</h3>
              <ul>
                <li><a href="https://lifemd.com/about">About Us</a></li>
                <li><a href="https://lifemd.com/platform">Platform</a></li>
                <li><a href="https://ir.lifemd.com/">Investors</a></li>
              </ul>
            </div>
            <div class="footer-item">
              <h3>Menu</h3>
              <ul>
                <li><a href="https://lifemd.com/help">Why LifeMD</a></li>
                <li><a href="https://lifemd.com/how-it-works">How It Works</a></li>
                <li><a href="https://lifemd.com/plans">Our Plans</a></li>
              </ul>
            </div>
            <div class="footer-item">
              <h3>App</h3>
              <div class="btn-download">
                <a href="https://play.google.com/store/apps/details?id=com.lifemd.care">
                  <img :src="require('@/assets/img/google-play.png')">
                </a>
                <a href="https://apps.apple.com/us/app/lifemd/id1584079757?platform=iphone" target="_blank">
                  <img :src="require('@/assets/img/app-store.png')">
                </a>
              </div>
              <a href="tel:800-852-1575" class="phone">
                <i class="ic-phone"></i>
                <span>(800) 852-1575</span>
              </a>
            </div>
          </div>
        </div>
        <div class="footer-copy">
          <div class="center">
            <p>Copyright © 2022 | LifeMD</p>
            <div>
              <a href="https://lifemd.com/accessibility" style="color: #5a8397;">Accessibility</a>
              &nbsp;&nbsp; <span></span> &nbsp;&nbsp;
              <a href="https://lifemd.com/consent-to-telehealth" style="color: #5a8397;">Telehealth</a>
              &nbsp;&nbsp; <span></span> &nbsp;&nbsp;
              <a href="https://lifemd.com/ccpa" style="color: #5a8397;">CCPA</a>
            </div>
            <div>
              <a href="https://lifemd.com/terms" style="color: #5a8397;">Terms &amp; Conditions</a>
              &nbsp;&nbsp; <span></span> &nbsp;&nbsp;
              <a href="https://lifemd.com/privacy" style="color: #5a8397;">Privacy Policy</a>
            </div>
          </div>
        </div>
        <div class="footer-copy last-footer">
          <div class="center" style="display:block;">
            <img src="https://static.legitscript.com/seals/10012446.png"
                 style="max-width: 100px; float: right; padding: 15px; position relative;">
            <p class="last-footer-text">If you are using a screen reader, or having trouble reading this website, please
              call LifeMD support at <a class="last-footer-a" href="tel:8663515907">(866) 351-5907</a>.</p>
            <p class="last-footer-text">Medical treatment from licensed providers is provided by the “LifeMD Affiliated
              P.C.s,” an affiliated network of medical Professional Corporations and Associations. To learn more, <a
                  class="last-footer-a last-footer-click" data-fancybox="" data-type="ajax"
                  data-src="/pages/pop-up.html" href="javascript:;">click here</a>.</p>
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>

<style>

</style>

<script>
import * as animationData from '@/assets/lottie/lines_u_block_1.json';
import VueLottiePlayer from "vue-lottie-player";
import VueSlickCarousel from "vue-slick-carousel";

export default {
  components: {
    vLottiePlayer: VueLottiePlayer,
    VueSlickCarousel
  },
  data() {
    return {
      lottieOptions: {path: animationData},
      settings: {
        infinite: !0,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: !0,
        autoplaySpeed: 5e3,
        arrows: !1,
        dots: !1,
        speed: 300,
        responsive: [{breakpoint: 650, settings: {slidesToShow: 1, slidesToScroll: 1}}]
      }
    }
  }
}
</script>
