<template>
  <div class="content">
    <div class="form-quiz" data-quiz-questions-quantity="21">

      <div v-if="showLoader" class="guiz-step-item active">
        <div class="guiz-step-item-inner text-center">
          <div class="container container-930px wow fadeIn">
            <div class="spinner-border text-primary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      </div>

      <component :key="componentKey" @onSubmit="onSubmit" v-bind="currentPage.propData"
                 :is="currentPage.type"></component>
    </div>
  </div>
</template>

<script>
import RadioQuestion from '../components/questions/RadioQuestion'
import InputQuestion from '../components/questions/InputQuestion'
import CheckBoxQuestion from '../components/questions/CheckBoxQuestion'
import SuccessPage from '../components/questions/SuccessPage'
import InfoPage from '../components/questions/InfoPage'
import DoctorsInfo from '../components/questions/DoctorsInfo'
import BuyNow from '../components/questions/BuyNow'
import axios from 'axios'
import posthog from 'posthog-js'

const { v4: uuidv4 } = require('uuid')

export default {
  components: {
    RadioQuestion,
    InputQuestion,
    CheckBoxQuestion,
    InfoPage,
    DoctorsInfo,
    BuyNow
  },
  data () {
    return {
      sessionId: '',
      questions: [
        {
          'id': '1000',
          'questionType': 'SUCCESS'
        },
        {
          'id': '100',
          'questionType': 'INFO_PAGE'
        },
        {
          'id': '101',
          'questionType': 'DOCTORS_INFO_PAGE'
        },
        {
          'id': '102',
          'questionType': 'BUY_NOW_PAGE'
        }
      ],
      onlyQuestions: [],
      currentPage: {
        type: null,
        propData: null
      },
      componentKey: 0,
      brazeUserId: null,
      ruleId: 'rule-1',
      questionsCount: 0,
      showLoader: true
    }
  },
  methods: {
    async onSubmit (input) {
      const question = await this.getQuestionById(input.questionId)
      if (question && question.question) {
        posthog.capture(question.question, input)
      }
      await this.apiCall(input)
      this.componentKey += 1
    },
    async apiCall (input, saveHis = true) {
      const { data } = await axios.post(`/account/${process.env.VUE_APP_ACCOUNT_ID}/answer/${this.sessionId}?rule_id=${this.ruleId}`, input)
      if (data && data.status) {
        const response = data.value
        if (saveHis)
          await this.saveHistory(response, input)
        this.currentPage = await this.getCurrentPage(response)
        this.currentPage.propData.params = response.params
        await this.fillAnswerOfNextQuestion(response.id)
      }
      this.$root.$emit('hide-loader', true)
      if (this.currentPage.type === RadioQuestion || this.currentPage.type === CheckBoxQuestion || this.currentPage.type === InputQuestion)
        await this.calculateProgress()
    },
    async getCurrentPage (res) {
      let question = null
      const nextQuestionId = res.id
      for (let i = 0; i < this.questions.length; i++) {
        if (nextQuestionId === this.questions[i].id) {
          question = this.questions[i]
          break
        }
      }
      if (question) {
        return {
          type: await this.getType(question.questionType),
          propData: { ...question }
        }
      }
      return {
        type: null,
        propData: null
      }
    },
    async getType (questionType) {
      switch (questionType) {
        case 'RADIO':
          return RadioQuestion
        case 'SUCCESS':
          return SuccessPage
        case 'INPUT':
          return InputQuestion
        case 'CHECKBOX':
          return CheckBoxQuestion
        case 'INFO_PAGE':
          return InfoPage
        case 'DOCTORS_INFO_PAGE':
          return DoctorsInfo
        case 'BUY_NOW_PAGE':
          return BuyNow
      }
      return null
    },
    async calculateProgress () {
      const currentQuestionId = this.currentPage.propData.id
      let currentIndex = 0
      let total = this.questionsCount

      let history = localStorage.getItem('history') || JSON.stringify([])
      history = JSON.parse(history)
      let matched = false
      for (let i = 0; i < history.length; i++) {
        const item = history[i]
        const qId = item.question.id
        if (qId === currentQuestionId) {
          currentIndex = i + 1
          matched = true
          break
        }
      }
      if (!matched) {
        currentIndex = history.length + 1
      }
      const percentage = currentIndex * 100 / total
      this.$root.$emit('update-progress', percentage)
    },
    async goBack () {
      const currentQuestionId = this.currentPage.propData.id
      console.log(currentQuestionId)
      let history = localStorage.getItem('history') || JSON.stringify([])
      history = JSON.parse(history)
      let currentIndex = -1
      for (let i = 0; i < history.length; i++) {
        const hItem = history[i]
        if (hItem.question.id === currentQuestionId) {
          currentIndex = i
          break
        }
      }
      if (currentIndex === -1 && history.length > 0) {
        currentIndex = history.length
      }
      if (currentIndex !== -1 && currentIndex !== 0) {
        if (currentIndex - 2 >= 0) {
          const prevPrevItem = history[currentIndex - 2]
          const prevItem = history[currentIndex - 1]
          const response = prevPrevItem.response
          const input = prevItem.input
          const qType = await this.getType(prevItem.question.questionType)
          this.currentPage = {
            type: qType,
            propData: {
              ...prevItem.question,
              params: response.params,
              oldInput: input
            }
          }
          if (qType === RadioQuestion || qType === CheckBoxQuestion || qType === InputQuestion) {
            console.log('-')
          } else {
            await this.goBack()
          }
        } else {
          await this.apiCall({ questionId: -1 }, false)
        }
      }
      this.componentKey += 1
      await this.calculateProgress()
    },
    async saveHistory (response, input) {
      if (!this.currentPage || !this.currentPage.propData) {
        return
      }
      const currentQuestionId = this.currentPage.propData.id
      let question = null
      for (let i = 0; i < this.questions.length; i++) {
        if (currentQuestionId === this.questions[i].id) {
          question = this.questions[i]
        }
      }
      const history = {
        question,
        input,
        response
      }
      let item = localStorage.getItem('history') || JSON.stringify([])
      item = JSON.parse(item)
      let found = false
      for (let i = 0; i < item.length; i++) {
        if (currentQuestionId === item[i].question.id) {
          item[i] = history
          found = true
          break
        }
      }
      if (!found) {
        item.push(history)
      }
      localStorage.setItem('history', JSON.stringify(item))
    },
    async getSessionId () {
      const sessionStartTime = localStorage.getItem('session-start-time')
      if (sessionStartTime) {
        const currentTime = new Date().getTime()
        const diff = (currentTime - sessionStartTime) / (1000 * 60 * 60)
        if (diff >= 72) {
          localStorage.removeItem('session-id')
          localStorage.removeItem('history')
        }
      }
      const sessionId = localStorage.getItem('session-id') || uuidv4()
      if (!localStorage.getItem('session-id')) {
        localStorage.setItem('session-id', sessionId)
        localStorage.setItem('session-start-time', new Date().getTime())
      }
      return sessionId
    },
    async getQuestions () {
      const { data } = await axios.get(`/account/${process.env.VUE_APP_ACCOUNT_ID}/questions`)
      if (data && data.status) {
        this.questions = this.questions.concat(data.value)
        this.onlyQuestions = data.value
      }
    },
    async copyQuestions () {
      await axios.post(`/account/${process.env.VUE_APP_ACCOUNT_ID}/session/${this.sessionId}/questions`)
    },
    async goToLastOpenedPage () {
      let history = localStorage.getItem('history') || JSON.stringify([])
      history = JSON.parse(history)
      if (history && history.length > 1) {
        const prevPrevItem = history[history.length - 2]
        const prevItem = history[history.length - 1]
        const response = prevPrevItem.response
        const input = prevItem.input
        this.currentPage = {
          type: await this.getType(prevItem.question.questionType),
          propData: {
            ...prevItem.question,
            params: response.params,
            oldInput: input
          }
        }
        return true
      }
      return false
    },
    async fillAnswerOfNextQuestion (questionId) {
      let history = localStorage.getItem('history') || JSON.stringify([])
      history = JSON.parse(history)
      for (let i = 0; i < history.length; i++) {
        const item = history[i]
        const qId = item.question.id
        if (questionId === qId) {
          const input = item.input
          this.currentPage.propData.oldInput = input
        }
      }
    },
    async storeMetaInfo () {
      const ip = await this.getIP()
      const browser = this.$browserDetect.meta.name
      const browserVersion = this.$browserDetect.meta.version
      const userAgent = this.$browserDetect.meta.ua
      await axios.post(`/account/${process.env.VUE_APP_ACCOUNT_ID}/session/${this.sessionId}/metadata`, {
        browser, browserVersion, userAgent, ip, brazeUserId: this.brazeUserId
      })
    },
    async getIP () {
      const { data } = await axios.get('https://api.ipify.org?format=json')
      if (data && data.ip) {
        return data.ip
      }
      return ''
    },
    async initBraze () {
      window.braze.initialize(process.env.VUE_APP_BRAZE_INSTANCE, {
        baseUrl: process.env.VUE_APP_BRAZE_URL
      })
      window.braze.openSession()
      return new Promise(resolve => {
        const self = this
        window.braze.getUser().getUserId(function (userId) {
          console.log('The UserID after wiping the Braze SDK Data is: ' + userId)
          self.brazeUserId = userId
          resolve()
        })
      })
    },
    async initPostHog () {
      posthog.init(process.env.VUE_APP_POSTHOG_API_KEY, {
        api_host: process.env.VUE_APP_POSTHOG_BASE_URL,
        capture_pageview: true
      })
    },
    async getRule () {
      const { data } = await axios.get(`/account/${process.env.VUE_APP_ACCOUNT_ID}/rule/${this.ruleId}`)
      if (data && data.status) {
        this.questionsCount = data.value.questions_count
      }
    },
    async checkRule () {
      let rule = localStorage.getItem('rule') || ''
      if (rule !== this.ruleId) {
        localStorage.setItem('rule', this.ruleId)
        localStorage.removeItem('session-id')
        localStorage.removeItem('history')
      }
    },
    async getQuestionById (id) {
      for (let i = 0; i < this.questions.length; i++) {
        const question = this.questions[i]
        if (question.id === id) {
          return question
        }
      }
      return null
    }
  },
  async mounted () {
    await this.initPostHog()
    this.showLoader = true
    if (this.$route.query.rule_id)
      this.ruleId = this.$route.query.rule_id
    await this.checkRule()
    await this.getRule()
    await this.initBraze()
    await this.getQuestions()
    this.sessionId = await this.getSessionId()
    await this.storeMetaInfo()
    this.showLoader = false
    if (!await this.goToLastOpenedPage())
      await this.apiCall({ questionId: -1 })
    this.copyQuestions()
    await this.calculateProgress()
    this.$root.$on('go-back', async () => {
      await this.goBack()
    })
  }
}

</script>
