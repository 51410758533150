<template>
  <div class="guiz-step-item active" data-quiz-question="16">
    <div class="guiz-step-item-inner text-center bg-transparent">
      <div class="container container-670px wow fadeIn" data-wow-delay="0.2s" v-wow="{ 'animation-name': 'fadeIn','animation-duration': '0.2s'}">
        <div class="block-step-heading text-center theme-color-blue wow fadeInUp" data-wow-delay="0.2s" v-wow="{ 'animation-name': 'fadeInUp','animation-duration': '0.2s'}">
          <h2>Board-Certified Doctors Are Ready to Meet With You </h2>
        </div>
        <div class="block-doctor-thumbs text-left wow fadeInUp" data-wow-delay="0.3s" v-wow="{ 'animation-name': 'fadeInUp','animation-duration': '0.3s'}">
          <div class="container-670px">
            <div class="doctor-thumb-item bg-white wow fadeInUp" data-wow-delay="0.4s" v-wow="{ 'animation-name': 'fadeInUp','animation-duration': '0.4s'}">
              <div class="row align-items-center">
                <div class="col-sm-3">
                  <div class="img-wr img-rounded">
                    <img class="img-rounded" src="@/assets/img/Dr-Jennell-Giles.png" alt="">
                  </div>
                </div>
                <div class="col-sm-9">
                  <h3 class="theme-color-blue">Dr. Jennell Giles</h3>
                  <h5>Family Physician</h5>
                </div>
              </div>
              <div class="d-block d-md-none">
                <a href="javascript:void(0);"
                   class="btn-item bg-theme-color-green-light wow zoomIn" data-wow-delay="0.4s" v-wow="{ 'animation-name': 'zoomIn','animation-duration': '0.4s'}">
                  <i><img src="@/assets/img/icon-lifemd.svg" alt=""></i>
                  <span>General</span>
                </a>
                <a href="javascript:void(0);"
                   class="btn-item bg-theme-color-green-light wow zoomIn" data-wow-delay="0.4s" v-wow="{ 'animation-name': 'zoomIn','animation-duration': '0.4s'}">
                  <i><img src="@/assets/img/icon-heart.svg" alt=""></i>
                  <span>Heart</span>
                </a>
                <a href="javascript:void(0);"
                   class="btn-item bg-theme-color-green-light wow zoomIn" data-wow-delay="0.4s" v-wow="{ 'animation-name': 'zoomIn','animation-duration': '0.4s'}">
                  <i><img src="@/assets/img/icon-children.svg" alt=""></i>
                  <span>Children</span>
                </a>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <h6>Experience:</h6>
                </div>
                <div class="col-sm-9">
                  <p>
                    <i class="theme-color-blue fa fa-graduation-cap"></i>
                    <span>Harvard Medical University</span>
                  </p>
                  <p>
                    <i class="theme-color-blue fa fa-graduation-cap"></i>
                    <span>Social and Family medicine, Montefiore Medical center</span>
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <h6>About:</h6>
                </div>
                <div class="col-sm-9">
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus pharetra
                    turpis ac consequat facilisis. Nullam nisi elit, luctus eleifend dolor
                    quis, tincidunt varius nisi. Mauris placerat neque at purus rutrum, id
                    fringilla massa vestibulum. Donec varius pretium ante, sed consequat
                    mauris pharetra id. </p>
                </div>
              </div>
            </div>
            <div class="doctor-thumb-item bg-white wow fadeInUp" data-wow-delay="0.4s" v-wow="{ 'animation-name': 'fadeInUp','animation-duration': '0.4s'}">
              <div class="row align-items-center">
                <div class="col-sm-3">
                  <div class="img-wr img-rounded">
                    <img class="img-rounded" src="@/assets/img/Dr-Joseph-Angel.png" alt="">
                  </div>
                </div>
                <div class="col-sm-9">
                  <h3 class="theme-color-blue">Dr. Joseph Angel</h3>
                  <h5>Family Physician</h5>
                </div>
              </div>
              <div class="d-block d-md-none">
                <a href="javascript:void(0);"
                   class="btn-item bg-theme-color-green-light wow zoomIn" data-wow-delay="0.4s" v-wow="{ 'animation-name': 'zoomIn','animation-duration': '0.4s'}">
                  <i><img src="@/assets/img/icon-lifemd.svg" alt=""></i>
                  <span>General</span>
                </a>
                <a href="javascript:void(0);"
                   class="btn-item bg-theme-color-green-light wow zoomIn" data-wow-delay="0.4s" v-wow="{ 'animation-name': 'zoomIn','animation-duration': '0.4s'}">
                  <i><img src="@/assets/img/icon-heart.svg" alt=""></i>
                  <span>Heart</span>
                </a>
                <a href="javascript:void(0);"
                   class="btn-item bg-theme-color-green-light wow zoomIn" data-wow-delay="0.4s" v-wow="{ 'animation-name': 'zoomIn','animation-duration': '0.4s'}">
                  <i><img src="@/assets/img/icon-children.svg" alt=""></i>
                  <span>Children</span>
                </a>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <h6>Experience:</h6>
                </div>
                <div class="col-sm-9">
                  <p>
                    <i class="theme-color-blue fa fa-graduation-cap"></i>
                    <span>Harvard Medical University</span>
                  </p>
                  <p>
                    <i class="theme-color-blue fa fa-graduation-cap"></i>
                    <span>Social and Family medicine, Montefiore Medical center</span>
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <h6>About:</h6>
                </div>
                <div class="col-sm-9">
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus pharetra
                    turpis ac consequat facilisis. Nullam nisi elit, luctus eleifend dolor
                    quis, tincidunt varius nisi. Mauris placerat neque at purus rutrum, id
                    fringilla massa vestibulum. Donec varius pretium ante, sed consequat
                    mauris pharetra id. </p>
                </div>
              </div>
            </div>
            <div class="doctor-thumb-item bg-white wow fadeInUp" data-wow-delay="0.4s" v-wow="{ 'animation-name': 'fadeInUp','animation-duration': '0.4s'}">
              <div class="row align-items-center">
                <div class="col-sm-3">
                  <div class="img-wr img-rounded">
                    <img class="img-rounded" src="@/assets/img/Dr-Glenn-Dahl.png" alt="">
                  </div>
                </div>
                <div class="col-sm-9">
                  <h3 class="theme-color-blue">Dr. Glenn Dahl</h3>
                  <h5>Family Physician</h5>
                </div>
              </div>
              <div class="d-block d-md-none">
                <a href="javascript:void(0);"
                   class="btn-item bg-theme-color-green-light wow zoomIn" data-wow-delay="0.4s" v-wow="{ 'animation-name': 'zoomIn','animation-duration': '0.4s'}">
                  <i><img src="@/assets/img/icon-lifemd.svg" alt=""></i>
                  <span>General</span>
                </a>
                <a href="javascript:void(0);"
                   class="btn-item bg-theme-color-green-light wow zoomIn" data-wow-delay="0.4s" v-wow="{ 'animation-name': 'zoomIn','animation-duration': '0.4s'}">
                  <i><img src="@/assets/img/icon-heart.svg" alt=""></i>
                  <span>Heart</span>
                </a>
                <a href="javascript:void(0);"
                   class="btn-item bg-theme-color-green-light wow zoomIn" data-wow-delay="0.4s" v-wow="{ 'animation-name': 'zoomIn','animation-duration': '0.4s'}">
                  <i><img src="@/assets/img/icon-children.svg" alt=""></i>
                  <span>Children</span>
                </a>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <h6>Experience:</h6>
                </div>
                <div class="col-sm-9">
                  <p>
                    <i class="theme-color-blue fa fa-graduation-cap"></i>
                    <span>Harvard Medical University</span>
                  </p>
                  <p>
                    <i class="theme-color-blue fa fa-graduation-cap"></i>
                    <span>Social and Family medicine, Montefiore Medical center</span>
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <h6>About:</h6>
                </div>
                <div class="col-sm-9">
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus pharetra
                    turpis ac consequat facilisis. Nullam nisi elit, luctus eleifend dolor
                    quis, tincidunt varius nisi. Mauris placerat neque at purus rutrum, id
                    fringilla massa vestibulum. Donec varius pretium ante, sed consequat
                    mauris pharetra id. </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="block-step-footer text-center wow fadeInUp" data-wow-delay="0.4s" v-wow="{ 'animation-name': 'fadeInUp','animation-duration': '0.4s'}">
          <button :disabled="showLoader" type="button" value="btn-step-next"
                  @click="submit"
                  class="btn-step-next btn-main bg-theme-color-green wow zoomIn"
                  data-wow-delay="0.8s" v-wow="{ 'animation-name': 'zoomIn','animation-duration': '0.8s'}">
            <div v-if="showLoader" class="spinner-border spinner-border-sm" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
            <span> Continue</span>
            <i class="fa fa-angle-right"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: String
  },
  data() {
    return {
      showLoader: false
    }
  },
  methods: {
    submit() {
      this.showLoader = true
      const input = {
        questionId: this.id,
      }
      this.$emit("onSubmit", input);
    }
  },
  mounted() {
    this.$root.$on('hide-loader', async () => {
      this.showLoader = false
    })
    const self = this;
    window.addEventListener('keyup', function () {
      if (event.keyCode === 13) {
        self.submit();
      }
    });
  }
}
</script>
