<template>
  <div class="guiz-step-item active" data-quiz-question="3">
    <div class="guiz-step-item-inner text-center bg-transparent">
      <div class="container container-670px wow fadeIn" data-wow-delay="0.2s"
           v-wow="{ 'animation-name': 'fadeIn','animation-duration': '0.2s'}">
        <div class="block-step-heading text-center theme-color-blue wow fadeInUp" data-wow-delay="0.2s"
             v-wow="{ 'animation-name': 'fadeInUp','animation-duration': '0.2s'}">
          <h2 v-html="question"></h2>
        </div>
        <div class="block-step-body text-center wow fadeInUp" data-wow-delay="0.3s"
             v-wow="{ 'animation-name': 'fadeInUp','animation-duration': '0.3s'}">
          <div class="container-300px">
            <div class="display-block">
              <div class="col-12" v-for="(item,index) in (select || [])" :key="item.label" style="margin-bottom: 20px">
                <select required :min="item.min" :max="item.max" data-rule-minlength="1"
                        v-model="answersSelect[index]"
                        name="quiz-question-height-feet">
                  name="quiz-question-height-feet">
                  <option value="">{{ item.label }}</option>
                  <option :value="option.key" :key="option.key" v-for="option in item.options">{{ option.value }}
                  </option>
                </select>
                <label v-if="item.validationError" class="error">{{ item.validationMessage }}</label>
              </div>
              <div class="col-12" v-for="(item,index) in input" :key="item.key">
                <label class="input-with-label wow zoomIn input-focused"
                       :class="{'form-row-invalid': item.validationError}" data-wow-delay="0.2s"
                       v-wow="{ 'animation-name': 'zoomIn','animation-duration': '0.2s'}">
                  <span class="input-label">{{ item.label }}</span>
                  <input v-if="item.mask" :id="item.key" :name="item.key" required :type="item.type"
                         v-mask="'(###) ###-####'"
                         v-model="answers[index]"
                         placeholder="">
                  <input v-if="!item.mask" :id="item.key" :name="item.key" required :type="item.type"
                         v-model="answers[index]"
                         placeholder="">
                  <label v-if="item.validationError" class="error"
                         :key="componentId">{{ item.validationMessage }}</label>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="block-step-footer text-center wow fadeInUp" data-wow-delay="0.2s"
             v-wow="{ 'animation-name': 'fadeInUp','animation-duration': '0.2s'}">
          <button :disabled="showLoader" type="button" value="btn-step-next"
                  @click="submit"
                  class="btn-step-next btn-main bg-theme-color-green wow zoomIn"
                  data-wow-delay="0.3s" v-wow="{ 'animation-name': 'zoomIn','animation-duration': '0.3s'}">
            <div v-if="showLoader" class="spinner-border spinner-border-sm" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
            <span> Continue</span>
            <i class="fa fa-angle-right"></i>
          </button>
          <button :disabled="showLoaderSkip" v-if="skipButton" type="button" @click="skip"
                  class="console-log-form-data btn-step-next-simple btn-main bg-gray wow zoomIn"
                  data-wow-delay="0.3s">
            <div v-if="showLoaderSkip" class="spinner-border spinner-border-sm" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
            <span> Skip</span>
            <i class="fa fa-angle-right"></i>
          </button>
          <p class="theme-color-blue" v-html="notes"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.form-row-invalid input {
  border-bottom-color: #9f0000;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

</style>

<script>
export default {
  props: {
    question: String,
    skipButton: Boolean,
    notes: String,
    id: String,
    input: Array,
    oldInput: Object,
    select: Array
  },
  data () {
    return {
      showLoader: false,
      showLoaderSkip: false,
      answers: [],
      answersSelect: [],
      componentId: 1
    }
  },
  methods: {
    validateEmail (email) {
      return String(email)
          .toLowerCase()
          .match(
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )
    },
    validateNumber (text) {
      let isnum = /^\d+$/.test(text)
      return isnum
    },
    skip () {
      this.showLoaderSkip = true
      const input = {
        questionId: this.id,
        inputAnswers: []
      }
      this.$emit('onSubmit', input)
    },
    submit () {
      const newItems = []
      let anyError = false
      for (let i = 0; i < this.input.length; i++) {
        const item = this.input[i]
        let answer = this.answers[i]
        if (this.input[i].mask) {
          answer = answer.replaceAll('(', '')
          answer = answer.replaceAll(')', '')
          answer = answer.replaceAll('-', '')
          answer = answer.replaceAll(/\s/g, '')
          this.answers[i] = answer
        }
        newItems.push({ ...item })
        if (item.required && (!answer || !answer.trim())) {
          newItems[i].validationError = true
          newItems[i].validationMessage = 'This field is required.'
          anyError = true
        } else if (item.required && item.type === 'email' && !this.validateEmail(answer)) {
          newItems[i].validationError = true
          newItems[i].validationMessage = 'Please enter valid email.'
          anyError = true
        } else if (item.required && item.type === 'number' && !this.validateNumber(answer)) {
          newItems[i].validationError = true
          newItems[i].validationMessage = 'Please enter valid number.'
          anyError = true
        } else {
          newItems[i].validationError = false
          newItems[i].validationMessage = ''
        }
      }
      this.input = newItems
      if (anyError) {
        return
      }

      const newSelects = []
      anyError = false
      for (let i = 0; this.select && i < this.select.length; i++) {
        const item = this.select[i]
        const answer = this.answersSelect[i]
        newSelects.push({ ...item })
        if (item.required && (!answer || !answer.trim())) {
          newSelects[i].validationError = true
          newSelects[i].validationMessage = 'This field is required.'
          anyError = true
        } else {
          newSelects[i].validationError = false
          newSelects[i].validationMessage = ''
        }
      }
      this.select = newSelects
      if (anyError) {
        return
      }

      if (this.answers.length) {
        this.showLoader = true
        const inputAnswers = []
        for (let i = 0; i < this.answers.length; i++) {
          inputAnswers.push({ key: this.input[i].key, answer: this.answers[i] })
        }
        const selectAnswers = []
        for (let i = 0; i < this.answersSelect.length; i++) {
          selectAnswers.push({ key: this.select[i].key, answer: this.answersSelect[i] })
        }
        const input = {
          questionId: this.id,
          inputAnswers: inputAnswers,
          selectAnswers: selectAnswers
        }
        this.$emit('onSubmit', input)
      }
    },
  },
  mounted () {
    for (let i = 0; i < this.input.length; i++) {
      this.answers.push('')
    }
    for (let i = 0; this.select && i < this.select.length; i++) {
      this.answersSelect.push('')
    }
    if (this.oldInput) {
      for (let i = 0; i < this.oldInput.inputAnswers.length; i++) {
        this.answers[i] = this.oldInput.inputAnswers[i].answer
      }
      for (let i = 0; this.oldInput.selectAnswers && i < this.oldInput.selectAnswers.length; i++) {
        this.answersSelect[i] = this.oldInput.selectAnswers[i].answer
      }
    }
    this.$root.$on('hide-loader', async () => {
      this.showLoader = false
    })
    const self = this
    window.addEventListener('keyup', function (event) {
      event.preventDefault()
      if (event.keyCode === 13) {
        self.submit()
      }
    })
    this.$root.$emit('header-update', 'light')
  }
}
</script>
