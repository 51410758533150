<template>
  <div class="guiz-step-item active" data-quiz-question="11">
    <div class="guiz-step-item-inner text-center bg-transparent">
      <div class="container container-800px wow fadeIn" data-wow-delay="0.2s"
           v-wow="{ 'animation-name': 'fadeIn','animation-duration': '0.2s'}">
        <div class="block-step-heading text-center theme-color-blue wow fadeInUp" data-wow-delay="0.2s"
             v-wow="{ 'animation-name': 'fadeInUp','animation-duration': '0.2s'}">
          <h2 v-html="question"></h2>
          <h4 class="color-gray">Select all that apply</h4>
          <label class="error" v-if="showError">This field is required.</label>
        </div>
        <div class="block-step-body text-center wow fadeInUp" data-wow-delay="0.3s"
             v-wow="{ 'animation-name': 'fadeInUp','animation-duration': '0.3s'}">
          <div class="container-800px">
            <div class="display-inline-block" v-for="item in options" :key="item">
              <label class="checkbox-styled wow zoomIn" data-wow-delay="0.2s"
                     v-wow="{ 'animation-name': 'zoomIn','animation-duration': '0.2s'}">
                <input type="checkbox" :value="item" v-model="answers" required>
                <span class="btn-checkbox">
												<span>{{ item }}</span>
											</span>
              </label>
            </div>
          </div>
          <div class="container-300px" v-if="otherRequired">
            <div class="display-block">
              <label class="checkbox-styled wow zoomIn" data-wow-delay="0.2s"
                     v-wow="{ 'animation-name': 'zoomIn','animation-duration': '0.2s'}">
                <input type="checkbox" @click="toggleOther" v-model="otherSelected" :checked="otherAnswer">
                <span class="btn-checkbox" style="font-size: 17px; font-weight: 500">
												Other
												<label v-if="otherSelected" class="input-with-label wow zoomIn" data-wow-delay="0.2s"
                               v-wow="{ 'animation-name': 'zoomIn','animation-duration': '0.2s'}">
													<span v-if="!otherAnswer" class="input-label color-gray">{{ otherQuestion }}</span>
													<textarea v-model="otherAnswer" placeholder=""></textarea>
												</label>
											</span>
              </label>
            </div>
          </div>
        </div>
        <div class="block-step-footer text-center wow fadeInUp" data-wow-delay="0.2s"
             v-wow="{ 'animation-name': 'fadeInUp','animation-duration': '0.2s'}">
          <button :disabled="showLoader" type="button" value="btn-step-next"
                  @click="submit"
                  class="btn-step-next btn-main bg-theme-color-green wow zoomIn"
                  data-wow-delay="0.3s" v-wow="{ 'animation-name': 'zoomIn','animation-duration': '0.3s'}">
            <div v-if="showLoader" class="spinner-border spinner-border-sm" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
            <span> Continue</span>
            <i class="fa fa-angle-right"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    question: String,
    options: Array,
    id: String,
    otherRequired: Boolean,
    otherQuestion: String,
    oldInput: Object
  },
  data () {
    return {
      showLoader: false,
      answers: [],
      otherAnswer: '',
      showError: false,
      otherSelected: false
    }
  },
  methods: {
    toggleOther(){
      this.otherSelected = !this.otherSelected
    },
    submit () {
      if (this.answers) {
        if (this.answers.length === 0 && !this.otherAnswer) {
          this.showError = true
          return
        }
        this.showLoader = true
        const input = {
          questionId: this.id,
          answers: this.answers
        }
        if (this.otherRequired) {
          input.otherAnswer = this.otherAnswer
        }
        this.$emit('onSubmit', input)
      }
    }
  },
  mounted () {
    this.answers = []
    if (this.oldInput) {
      this.answers = this.oldInput.answers
      this.otherAnswer = this.oldInput.otherAnswer
      if (this.otherAnswer) {
        this.otherRequired = true
        this.otherSelected = true
      }
    }
    this.$root.$on('hide-loader', async () => {
      this.showLoader = false
    })
    const self = this
    window.addEventListener('keyup', function () {
      if (event.keyCode === 13) {
        self.submit()
      }
    })
    this.$root.$emit('header-update', 'light')
  }
}
</script>
